<template>
    <div class="Template">
         <div class="flex" style="margin-bottom: 35px">
             <a-button-group size="large">
                 <a-button class="MyStatus" :class="MyStatus?'':'ant-btn-primary'" @click="_MyStatus(1)">活动模板</a-button>
                 <a-button class="MyStatus" :class="MyStatus?'ant-btn-primary':''" @click="_MyStatus(2)">我创建的</a-button>
             </a-button-group>
             <a-button type="primary" style="margin-right: 35px" @click="_add">+&nbsp;新建模板</a-button>
         </div>
        <!--搜索start-->
        <div style="margin-bottom: 22px">
            <a-select class="search-group" v-model="SearchCategory"  placeholder="请选择">
                <a-select-option v-if="categoryList" v-for="item in categoryList" :value="item.Id">{{item.title}}</a-select-option>
            </a-select>
            <a-input v-model="KeyWord" class="search-group" placeholder="请输入活动名称" />
            <a-button @click="Reset" style="margin-right: 12px">重置</a-button>
            <a-button @click="Search" type="primary">查询</a-button>
        </div>
        <!--搜索end-->

        <!--列表start-->
        <a-card v-if="dataList" v-for="(item,index) in dataList"  hoverable class="content" >
            <!--<div>-->
            <img alt="example" :src="item.CoverImg+'?imageView2/1/w/246/h/168'" slot="cover"/>
            <!--</div>-->

            <a-card-meta>
                <template slot="description">
                    <div class="flex">
                        <span class="content-explain" >{{item.Title}}</span>
                        <a-dropdown>
                            <a class="ant-dropdown-link" href="#" > <i @mouseover="_show(1,1)" @mouseout="_hide(1)" class="iconfont icon-shenglve" :style="active1==1?'':'color:#999999FF'"></i> </a>
                            <a-menu slot="overlay">
                                <a-menu-item>
                                    <a href="javascript:;" @click="preview(item.Id)"><i class="iconfont icon-bianji"></i>&nbsp;编辑</a>
                                </a-menu-item>
                                <a-menu-item>
                                    <a href="javascript:;" @click="preview(item.Id)"><i   class="iconfont icon-bianji"></i>&nbsp;预览</a>
                                </a-menu-item>
                                <a-menu-item>
                                    <a-popconfirm  placement="bottom" cancelText="取消" okText="确定" @confirm="DelActivity(item.Id,index)" @cancel="maxClassify=-1">
                                        <template slot="title">
                                            <p>确定删除么？</p>
                                        </template>
                                        <i class="iconfont icon-shanchu- ">&nbsp;删除</i>
                                    </a-popconfirm>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </div>
                    <div class="content-detail">{{item.Objective?item.Objective:'&nbsp;'}}</div>
                    <span class="content-category">{{item.Name}}</span>
                    <div class="contant-tags">
                        <span v-if="item.Tags.length>0" v-for="tags in item.Tags">{{tags}}</span>
                    </div>
                </template>
            </a-card-meta>
        </a-card>


        <!--列表end-->
        <!--创建start-->
        <a-drawer
                title="新建活动模板"
                width="1000"
                :closable="false"
                @close="onClose"
                :visible="visible"
        >
            <h1 class="title">活动介绍</h1>
            <div class="form-item" style="margin-bottom: 100px">
                <div class="flex-inline">
                    <label class="label-text">活动主题</label>
                    <a-input v-model="Title" class="group-item" placeholder="请输入" />
                </div>
                <div class="flex-inline">
                    <label class="label-text">所需员工</label>
                    <a-input v-model="PersonNum" class="group-item" placeholder="请输入" />
                </div>
                <div class="flex-inline">
                    <label class="label-text">难易程度</label>
                    <a-rate v-model="Score" />
                </div>
                <div class="flex-inline">
                    <label class="label-text">活动标签</label>
                    <template v-for="(tag, index) in tags">
                        <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
                            <a-tag :key="tag" :closable="index !== 0" :afterClose="() => handleClose(tag)">
                                {{`${tag.slice(0, 20)}...`}}
                            </a-tag>
                        </a-tooltip>
                        <a-tag v-else :key="tag" closable="" :afterClose="() => handleClose(tag)">
                            {{tag}}
                        </a-tag>
                    </template>
                    <a-input
                            v-if="inputVisible"
                            ref="input"
                            type="text"
                            size="small"
                            :style="{ width: '78px' }"
                            :value="inputValue"
                            @change="handleInputChange"
                            @blur="handleInputConfirm"
                            @keyup.enter="handleInputConfirm"
                    />
                    <a-tag v-else @click="showInput" style="background: #fff; borderStyle: dashed;">
                        <a-icon type="plus" /> 自定义
                    </a-tag>
                </div>
                <div class="flex-inline">
                    <label class="label-text">活动目的</label>
                    <a-input v-model="Objective" class="group-item" placeholder="请输入" />
                </div>
                <div class="flex-inline">
                    <label class="label-text">活动分类</label>
                    <a-select class="group-item" v-model="Category"  placeholder="请选择">
                        <a-select-option v-if="categoryList" v-for="item in categoryList" :value="item.Id">{{item.title}}</a-select-option>
                    </a-select>
                </div>
                <div class="flex-inline">
                    <label class="label-text">费用项</label>
                    <a-input v-model="Free" class="group-item" placeholder="请输入" />
                </div>
                <div class="flex-inline">
                    <label class="label-text">活动设备</label>
                    <a-input v-model="Equipment" class="group-item" placeholder="请输入" />
                </div>
                <div class="flex-inline">
                    <label class="label-text">活动说明</label>
                    <div class="group-item" style="width: 100%;height: 175px;overflow: auto">
                        <quill-editor
                                v-model="Content"
                                ref="myQuillEditor"
                                :options="editorOption"
                                @change="onEditorChange($event)">
                        </quill-editor>
                    </div>

                </div>
                <div class="flex-inline">
                    <label class="label-text">封面图</label>
                    <div v-if="CoverImg" class="Covermap-img" @mouseover="_CovermapShow" @mouseleave="_CovermapHide">
                        <img style="width: 100%;height: 100%" :src="CoverImg" alt="">
                        <div v-if="CovermapStatus"  class="Covermap-show" >更换封面
                            <input type="file" class="upload-bth"  @change="changeImage($event)">
                        </div>

                    </div>
                    <div v-else class="flex-inline">
                        <div class="Covermap">
                            <span style="font-size: 20px">+</span>
                            <span>选择封面</span>
                            <input type="file" class="upload-bth"  @change="changeImage($event)">
                        </div>
                        <div class="flex-inline" style="margin-left: 50px">
                            <label class="label-text">剪裁封面</label>
                            <div class="vueCropper-warp">
                                <vueCropper v-if="option.img"
                                        ref="cropper"
                                        :img="option.img"
                                        :outputSize="option.size"
                                        :outputType="option.outputType"
                                        :info="true"
                                        :full="option.full"
                                        :canMove="option.canMove"
                                        :canMoveBox="option.canMoveBox"
                                        :original="option.original"
                                        :autoCrop="option.autoCrop"
                                        :fixed="option.fixed"
                                        :fixedNumber="option.fixedNumber"
                                        :centerBox="option.centerBox"
                                        :autoCropWidth="option.autoCropWidth"
                                        :autoCropHeight="option.autoCropHeight"
                                        :infoTrue="option.infoTrue"
                                        :fixedBox="option.fixedBox"
                                        @realTime="realTime"
                                ></vueCropper>
                                <div v-if="option.img" style="margin-top: 15px;text-align: right">
                                    <a-button  style="marginRight: 8px" >删除</a-button>
                                    <a-button @click="finish()" type="primary">确认</a-button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-inline">
                    <h1 class="title">附件</h1>
                    <div class="Add-bth">+
                        <input type="file" class="upload-bth"  @change="changeEnclosure($event)">
                    </div>
                </div>
                <div class="flex-inline" style="display: -webkit-inline-box;">
                    <div class="enclosure" v-if="Enclosure.length>0" v-for="(item,index) in Enclosure">
                       <div class="enclosure-file">
                           <img v-if="item.FileStatus ==1" :src="item.FileUrl+'?imageView2/1/w/246/h/168'" alt="">
                           <i v-else-if="item.FileStatus ==2" class="iconfont icon-tubiao_shipin"></i>
                       </div>
                        <div class="flex file-warp">
                            <span class="file-name" >{{item.FileName}}</span>
                            <a-dropdown>
                                <a class="ant-dropdown-link" href="#" > <i @mouseover="_show(1,1)" @mouseout="_hide(1)" class="iconfont icon-shenglve" :style="active1==1?'':'color:#999999FF'"></i> </a>
                                <a-menu slot="overlay">
                                    <a-menu-item>
                                        <a href="javascript:;" @click="SaveEnclosure(index)"><i class="iconfont icon-bianji"></i>&nbsp;编辑</a>
                                    </a-menu-item>
                                    <a-menu-item>
                                        <a href="javascript:;" ><i   class="iconfont icon-bianji"></i>&nbsp;预览</a>
                                    </a-menu-item>
                                    <a-menu-item>

                                        <a-popconfirm  placement="bottom" cancelText="取消" okText="确定" @confirm="DelEnclosure(index)" @cancel="maxClassify=-1">
                                            <template slot="title">
                                                <p>确定删除么？</p>
                                            </template>
                                            <i class="iconfont icon-shanchu- ">&nbsp;删除</i>
                                        </a-popconfirm>
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                            <a-drawer
                                    title="附件修改"
                                    width="600"
                                    :closable="false"
                                    @close="onChildrenDrawerClose"
                                    :visible="childrenDrawer"
                            >
                                <a-input v-if="filesName" v-model="filesName"  class="group-item" placeholder="请输入" />
                                <div class="drawer-buttom">
                                    <a-button style="marginRight: 8px" @click="onClose">
                                        取消
                                    </a-button>
                                    <a-button @click="fileSave" type="primary">
                                        修改
                                    </a-button>
                                </div>
                            </a-drawer>
                        </div>
                    </div>
                </div>

            </div>
            <div class="drawer-buttom">
                <a-button style="marginRight: 8px" @click="onClose">
                    取消
                </a-button>
                <a-button @click="submitInfo" type="primary">
                     创建
                </a-button>
            </div>
        </a-drawer>
        <!--创建end-->
    </div>

</template>

<script>
    import Vue from 'vue'
    import VueCropper from 'vue-cropper'
    Vue.use(VueCropper)
    import VueQuillEditor from 'vue-quill-editor'

    // require styles
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    Vue.use(VueQuillEditor, /* { default global options } */)
    export default {
        name: "Template",
        data(){
            return{
                userinfo:JSON.parse(sessionStorage.getItem("userInfo")),
                Title:'',
                PersonNum:'',
                Category:'',
                Free:'',
                Equipment:'',
                CoverImg:'',
                CoverImgName:'',
                Content: '',
                Objective:'',
                MyStatus:false,
                categoryList:'',
                visible:false,
                EllipsisStatus:false,
                active1:-1,
                active2:-1,
                active3:-1,
                active4:-1,
                Score:2,
                inputVisible: false,
                tags: ['会员活动','1-2岁宝宝'],
                inputValue: '',
                editor:'',
                visible2:false,
                childrenDrawer:false,
                option: {
                    img: '',
                    outputSize:1, //剪切后的图片质量（0.1-1）
                    full: false,//输出原图比例截图 props名full
                    outputType: 'png',
                    canMove: true,
                    original: false,
                    canMoveBox: true,
                    autoCrop: true,
                    autoCropWidth: 460,
                    autoCropHeight: 186,
                    fixedBox: true
                },
                CovermapStatus:false,
                Enclosure:[],//附件
                fileIndex:'',
                editorOption: { /* quill options */ } ,
                page:0,
                Limit:12,
                KeyWord:'',
                SearchCategory:'',
                myuid:'',
                Count:'',
                dataList:'',
                filesName:'',
            }
        },
        created(){
            this._categoryList();
            this.loadData();
        },
        methods:{
            loadData(){
                let self = this;
                this.$axios.get(1062,{
                    page:self.page,
                    Limit:self.Limit,
                    Title:self.KeyWord,
                    uid:self.myuid,
                    Category:self.SearchCategory,
                },res=>{
                   if(res.data.code==1){
                       self.Count = res.data.message.Count;
                       self.dataList = res.data.message.data;
                   }
                })
            },
            Search(){
                this.loadData();
            },
            Reset(){
                this.KeyWord = '';
                this.SearchCategory = '';
                this.loadData();
            },
            DelActivity(id,index){
                console.log(id);
                let self = this;
                this.$axios.get(1063,{
                    uid:self.userinfo.uid,
                    Id:id,
                },res=>{
                    if(res.data.code==1){
                        self.dataList.splice(index,1);
                        this.$message.success(res.data.message);
                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            },
            submitInfo(){
                let self = this;
                this.$axios.post(1060,{
                    Title:self.Title,
                    PersonNum:self.PersonNum,
                    Category:self.Category,
                    Score:self.Score,
                    Tags:JSON.stringify(self.tags),
                    Free:self.Free,
                    Equipment:self.Equipment,
                    Content:self.Content,
                    CoverImg:self.CoverImg,
                    Objective:self.Objective,
                    Enclosure:JSON.stringify(self.Enclosure),
                    uid:self.userinfo.uid,
                    username:self.userinfo.name,
                },res=>{
                    if(res.data.code==1){
                        this.visible= false;
                        this.loadData();
                        this.$message.success(res.data.message);

                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            },
            //富文本
            onEditorChange(){}, // 内容改变事件

            _MyStatus(value){
                if(value==1){
                    this.myuid = '';
                    this.loadData();
                    this.MyStatus = false;
                }else{
                    this.myuid = this.userinfo.uid.userinfo;
                    this.loadData();
                    this.MyStatus = true;
                }
            },
            //封面图上传预览
            changeImage(e) {
                var file = e.target.files[0];
                var reader = new FileReader()
                var that = this
                reader.readAsDataURL(file)
                reader.onload = function(e) {
                    that.option.img = this.result
                    that.CoverImgName = file.name;
                    that.CoverImg = '';
                }
            },
            _CovermapShow(){
                this.CovermapStatus = true;
            },
            _CovermapHide(){
                this.CovermapStatus = false;
            },
            realTime(data) {
               
                // this.previews = data
            },
            //图片裁剪
            finish(){
                let self = this;
                this.$refs.cropper.getCropData((data) => {
                    this.$axios.post(1061,{
                        CoverImg:data,
                        CoverImgName:self.CoverImgName,
                    },res=>{
                        if(res.data.code==1){
                            self.CoverImg = res.data.message;
                            self.CoverImgName = '';
                        }else{
                            this.$message.error(res.data.message)
                        }
                    })
                })
            },
           
            fileType(type){
                let status = -1;
                let type_str = type.slice(12);
                //图片1,视频2,3pdf,4excel、Word
                if(type.indexOf("image/") == 0){
                    status = 1;
                }else if(type.indexOf("video/" ==0)){
                    status = 2;
                }else if(type.indexOf("application/" ==0)){
                    if(type_str =='pdf'){
                        status = 3;
                    }else{
                        status = 4;
                    }
                }else{
                    status = 0;
                }
                return status;
            },
            changeEnclosure(e){
                let file = e.target.files;
                let self = this;
                this.__uploadFile__(file).then(res=>{
                    if(res){
                        res[0].FileStatus = this.fileType(res[0].FileType);
                        self.Enclosure.push(res[0]);
                    }
                })

            },
            SaveEnclosure(index){
                this.fileIndex = index;
                this.filesName = this.Enclosure[index].FileName;
                this.childrenDrawer = true;
            },
            fileSave(){
                this.Enclosure[this.fileIndex].FileName = this.filesName;
                this.fileIndex = '';
                this.filesName = '';
                this.childrenDrawer = false;
            },
            DelEnclosure(index){
                 this.Enclosure.splice(index,1)
            },
            onChildrenDrawerClose() {
                this.childrenDrawer = false;
            },
            _categoryList(){
                let self = this;
                this.$axios.get(1200,{
                    label:'activitytemplate',
                },res=>{
                    self.categoryList = res.data.data;
                })
            },
            onClose(){
                this.visible= false;
            },
            _add(){
                this.visible = true;
            },
            _show(index,i){
                if(i ==1){
                    this.active1 = index;
                }else if(i==2){
                    this.active2 = index;
                }else if(i==3){
                    this.active3 = index;
                }else if(i==2){
                    this.active4 = index;
                }
                
            },
            _hide(i){
                if(i==1){
                    this.active1 = -1;
                }else if(i==2){
                    this.active2 = -1;
                }else if(i==3){
                    this.active3 = -1;
                }else if(i==2){
                    this.active4 = -1;
                }
            },
            handleClose(removedTag) {
                const tags = this.tags.filter(tag => tag !== removedTag);
                this.tags = tags;
            },
            showInput() {
                this.inputVisible = true;
                this.$nextTick(function() {
                    this.$refs.input.focus();
                });
            },

            handleInputChange(e) {
                this.inputValue = e.target.value;
            },

            handleInputConfirm() {
                const inputValue = this.inputValue;
                let tags = this.tags;
                if (inputValue && tags.indexOf(inputValue) === -1) {
                    tags = [...tags, inputValue];
                }
                console.log(tags);
                Object.assign(this, {
                    tags,
                    inputVisible: false,
                    inputValue: '',
                });
            },
            preview(id){
                this.$router.push({path: '/template_info',query:{id:id}})
            },
            _uploadEditor(files){
                this.__uploadFile__(files).then(res=>{
                    if(res){
                        console.log(res);
                    }
                })
            },
        }
    }
</script>
<style lang="less" scoped>
    *{
        font-family:Microsoft YaHei;
    }

    .Template{
        background: #ffffff;
        min-height: 800px;
        padding: 20px;
    }
   .Template .enclosure{
        width: 145px;
        margin-right: 15px;
        border:1px solid rgba(224,225,226,1);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 10px;
        display: inline-block;
        .enclosure-file{
            height: 96px;
            width: 100%;
            line-height: 95px;
            text-align: center;
            background: #ffffff;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .file-warp{
            padding: 5px 10px;

        }
        .file-name{

            width: 68px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color:rgba(45,62,93,1);
            font-size: 12px;
        }
    }
    .Template .MyStatus{
        font-size: 14px;
    }
  .Template  .upload-bth{
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
   .Template .Covermap-img{
        width: 165px;
        height: 81px;
        position: relative;
        .Covermap-show{
            width: 100%;
            background: #000000;
            opacity: 0.48;
            color: #ffffff;
            font-size: 14px;
            text-align: center;
            position: absolute;
            bottom: 0;
            left: 0;
            cursor: pointer;
        }
    }
  .Template .Add-bth{
        color: rgba(41,148,255,1);
        border: 2px solid rgba(41,148,255,1);
        border-radius: 50%;
        width: 20px;
        text-align: center;
        height: 20px;
        line-height: 10px;
        font-size: 20px;
        margin-left: 10px;
        position: relative;
    }
   .Template .search-group{
        width: 155px;
        display: inline-block;
        margin-right: 12px;
    }
   .Template .title{
        color:rgba(102,102,102,1);
        font-size: 14px;
        position: relative;
        padding-left: 15px;
        font-weight: bold;
    }
  .Template .title::after{
        content: '';
        width:4px;
        height: 16px;
        background:rgba(41,148,255,1);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
   .Template .form-item{
        margin-left: 15px;
        .flex-inline{
            display: flex;
            margin-bottom: 15px;
            .label-text{
                width: 65px;
                text-align: left;
                color: #ABB0BFFF;
                font-size: 12px;
                height: 36px;
                line-height: 36px;
            }
            .group-item{
                height: 36px;
                line-height: 36px;
                width: 390px;
            }
        }
    }
    .Template .Covermap{
        text-align: center;
        border:2px dashed rgba(224,225,226,1);
        position: relative;
        padding: 15px 55px;
        border-radius: 5px;
        max-width: 180px;
        max-height: 85px;
        span{
            display: block;
            color:rgba(41,148,255,1);
            font-size: 14px;
        }
    }
   .Template .vueCropper-warp{
        width:461px;
        height:319px;
        background:rgba(255,255,255,1);
        border:1px solid rgba(204,209,220,1);
        opacity:1;
        border-radius:2px;
       
    }
   .Template .content{
        width: 276px;
        margin-bottom: 20px;
        display: inline-block;
        margin-right: 20px;
        img{
            width: 100%;
            height: 168px;
        }
        .content-explain{
            width: 150px;
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size:16px;
            color:rgba(45,62,93,1);
        }
        .content-detail{
            color:rgba(153,153,153,1);
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .content-category{
            color: #ffffff;
            font-size: 12px;
            background:rgba(255,130,35,1);
            border-radius:0px 0px 2px 2px;
            height: 20px;
            line-height: 20px;
            display: inline-block;
            padding:0px 5px;
            position: absolute;
            top:0;
            right: 10px;
        }
        .contant-tags{
            width: 100%;
            position: absolute;
            text-align: right;
            overflow: hidden;
            white-space: nowrap;
            left: 0px;

            top: 140px;
            span{
                display: inline-block;
                background:rgba(0,0,0,1);
                opacity:0.48;
                border-radius:60px;
                color: #ffffff;
                font-size: 12px;
                padding: 0px 5px;
                margin-right: 5px;
            }
        }
    }
   .Template .drawer-buttom{
        position: absolute;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        left: 0;
        background: #ffffff;
        borderRadius: 0 0 4px 4px;
    }
</style>